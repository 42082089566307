<template>
    <div :class="`m-3 p-2 wrapper  wrapper--default`">
        <h4 class='text-center'>Aflevering gepland met openstaande WO's</h4>
        <hr>
        <Loading v-if="loading" />
        <table v-else :class="`w-100 table--perweek table__border--default`">
            <thead :class="`table__head--default`">
                <th class="table__head--text">Plandatum</th>
                <th class="table__head--text">Plantijd</th>
                <th class="table__head--text">Plandatum laatste WO</th>
                <th class="table__head--text">Verkoper</th>
                <th class="table__head--text">Item</th>
                <th class="table__head--text">Klant</th>
                <th class="table__head--text">Omschrijving</th>
                <th class="table__head--text">Kenteken</th>
                <th class="table__head--text">Aflevering</th>
                <th class="table__head--text">Werkopdrachten Int/Ext</th>
            </thead>
            <tbody>
                <tr v-for="(item, key) in planned_with_open_wo" :key=key class="`table__row--color`">
                    <td class="table__cell--default text-center">{{ item.datum }}</td>
                    <td class="table__cell--default text-center">{{ item.tijd }}</td>
                    <td class="table__cell--default text-center" style="background-color: #ff3333;">{{ item.DateWo }}</td>
                    <td class="table__cell--default">{{ item.naam }}</td>
                    <td class="table__cell--default">
                        <ImageHover :itemnummer="item.Itemnummer" :bu="item.bu" />
                    </td>
                    <td class="table__cell--default">{{ item.Klant }}</td>
                    <td class="table__cell--default">{{ item.Omschrijving }}</td>
                    <td class="table__cell--default">{{ item.Kenteken }}</td>
                    <td class="table__cell--default">{{ item.Aflevering }}</td>
                    <td class="table__cell--default table__data--right">
                        <span v-if="(item.IsOpenIntern == 1)" class="red"><strong>{{
                            item.CountIntern
                                }}</strong></span><span v-else>{{ item.CountIntern }}</span> /
                        <span v-if="(item.IsOpenExtern == 1)" class="red"><strong>{{
                            item.CountExtern
                                }}</strong></span><span v-else>{{ item.CountExtern }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import request from '@/functions/request.js';
import ImageHover from "@/components/ImageHover.vue"
import Loading from '@/components/Loading.vue';

export default {
    components: { ImageHover, Loading },
    data: () => ({
        planned_with_open_wo: null,
        loading: null,
    }),
    created() {
        this.loading = true;
        this.getData();
    },
    methods: {
        getData() {
            request(`planned-with-open-wo`, "GET").then(({ planned_with_open_wo }) => {
                this.planned_with_open_wo = planned_with_open_wo;
                this.loading = false;
            })
        }
    },
};
</script>
